import React, { useEffect } from 'react';
import phil_map from '../../../static/svg/phil-map.svg';

//gsap happens
import { gsap, Power3 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export const AboutProudFil = () => {
    useEffect(() => {
        gsap.from('.gsap_proud_about_img ', {
            duration: 1,
            y: '10',
            scale: 0.6,
            opacity: 0,
            ease: Power3.easeInOut,
            scrollTrigger: {
                trigger: '.proud_about',
                start: 'top 20%',
                end: 'bottom 50%',
                // markers: true,
                toggleActions: 'play none none reverse',
                // delay: 0.5,
            },
            stagger: 0.2,
        });
        gsap.from('.gsap_proud_about_text ', {
            duration: 1,
            y: '10',
            opacity: 0,
            ease: Power3.easeInOut,
            scrollTrigger: {
                trigger: '.proud_about',
                start: 'top 20%',
                end: 'bottom 50%',
                // markers: true,
                toggleActions: 'play none none reverse',
                // delay: 0.5,
            },
            stagger: 0.2,
        });
    }, []);

    return (
        <>
            <section className="proud d-flex-col align-items-center justify-content-center proud_about">
                <div className="proud_modal d-flex-row align-items-center justify-content-center">
                    <img src={phil_map} alt="Philippines Map" className="gsap_proud_about_img" />
                    <div className="proud_modal_text d-flex-col gap-1">
                        <h2 className="gsap_proud_about_text">
                            Proudly <span>Filipino!</span>
                        </h2>
                        <p className="gsap_proud_about_text">
                            Zipher Solutions is based from the <span>Philippines</span> and has
                            handled multiple international clients.
                            <br /> Our teams consist of 100% Filipinos.
                        </p>
                        <p className="gsap_proud_about_text">
                            Zipher Solutions aims to make <span>Filipinos</span> known for being one
                            of the <span>best IoT Engineers</span> in the world, backed by the unique Filipino
                            Ingenuity, the need for IoT is more evident now more than ever.
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
};
