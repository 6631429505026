import React, { useEffect } from 'react';

//item components
import card_bground from '../../../static/svg/showcase-bground.svg';
import card_bground_mobile from '../../../static/svg/showcase-bground-mobile.svg';
import cup from '../../../static/svg/core-cup.svg';
import bground_left from '../../../static/svg/core-bground-left.svg';
import bground_right from '../../../static/svg/core-bground-right.svg';
import svgA from '../../../static/svg/core-icon-a.svg';
import svgB from '../../../static/svg/core-icon-b.svg';
import svgC from '../../../static/svg/core-icon-c.svg';
import title_bground from '../../../static/svg/core-title-bground.svg';

//gsap happens
import { gsap, Power3 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

//PLUGGIN
import CountUp from 'react-countup';

const coreTeams = [
    {
        icon: svgA,
        title: 'Management',
        desc: (
            <>
                Our professional team of experienced individuals ensures all projects ticks all the
                requirements and delivers the best value for our clients.
            </>
        ),
    },
    {
        icon: svgB,
        title: 'IoT Team',
        desc: (
            <>
                With a diverse team of skilled IoT Engineers developing integrated network
                solutions, we are slowly moving into the edge of Industry 4.0.
            </>
        ),
    },
    {
        icon: svgC,
        title: 'Software Team',
        desc: (
            <>
                From design conceptualization to final delivery, our team of technical pioneers
                develops ideas into scalable solutions using the latest in frontend and backend
                technologies.
            </>
        ),
    },
];

const showcase = [
    {
        int: <CountUp duration={6} start={0} end={50} />,
        sub: 'PROJECT RELEASED',
    },
    {
        int: <CountUp duration={6} start={0} end={18} />,
        sub: 'KNOWN CLIENTS',
    },
    {
        int: <CountUp duration={6} start={0} end={64} />,
        sub: 'LOREM IPSUM',
    },
];

export const AboutCoreTeam = () => {
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        gsap.from('.gsap_teams_about_img ', {
            duration: 1,
            y: '10',
            scale: 0.6,
            opacity: 0,
            ease: Power3.easeInOut,
            scrollTrigger: {
                trigger: '.core_teams_about',
                start: 'top 10%',
                end: 'bottom 25%',
                // markers: true,
                toggleActions: 'play none none reverse',
                // delay: 0.5,
            },
            stagger: 0.2,
        });
        gsap.from('.gsap_teams_text ', {
            duration: 1,
            y: '10',
            opacity: 0,
            ease: Power3.easeInOut,
            scrollTrigger: {
                trigger: '.core_teams_about',
                start: 'top 10%',
                end: 'bottom 25%',
                // markers: true,
                toggleActions: 'play none none reverse',
                // delay: 0.5,
            },
            stagger: 0.2,
        });
    }, []);

    return (
        <>
            <section className="core_teams d-flex-col align-items-center justify-content-center core_teams_about">
                <div className="core_teams_content d-flex-col align-items-center justify-content-center">
                    <div className="cards_container d-flex-row align-items-center justify-content-center gap-3 hidden">
                        {showcase.map((card, i) => (
                            <div key={i} className="card">
                                <div className="title">
                                    <h1>{card.int}+</h1>
                                    <p>{card.sub}</p>
                                </div>
                                <img src={card_bground} alt="Card Background" />
                            </div>
                        ))}
                    </div>
                    <div className="cards_container_mobile d-flex-row align-items-center justify-content-center hidden">
                        <div className="card">
                            <div className="title">
                                {showcase.map((card, i) => (
                                    <div
                                        key={i}
                                        className="title_group d-flex-row align-items-center gap-1"
                                    >
                                        <img src={cup} alt="Zipher Cup Img" />
                                        <div className="d-flex-col justify-content-center">
                                            <h2>{card.int}+</h2>
                                            <p>{card.sub}</p>
                                        </div>
                                        <div className="line"></div>
                                    </div>
                                ))}
                            </div>
                            <img src={card_bground_mobile} alt="Card Background" />
                        </div>
                    </div>
                    <div className="title text-center">
                        <h2>Core teams</h2>
                        <img src={title_bground} alt="Title Background" />
                    </div>
                    <div className="card_team text-center d-flex align-items-center justify-content-center ">
                        {coreTeams.map((team, i) => (
                            <div
                                key={i}
                                className="card_team_item d-flex-col align-items-center justify-content-center"
                            >
                                <img
                                    src={team.icon}
                                    alt="Management Icon"
                                    className="gsap_teams_about_img"
                                />
                                <p>{team.title}</p>
                                <p>{team.desc}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
};
