import React from 'react';
import AppLayout from '../layout/AppLayout';
import Footer from '../layout/Footer';

import {
    AboutBanner,
    AboutAbout,
    AboutCoreTeam,
    AboutProudFil,
    AboutContact,
} from '../components/about';
import { Helmet } from 'react-helmet';

const AboutPage = () => {
    return (
        <>
            <Helmet>
                <title>Zipher Solutions</title>
                <meta name="robots" content="index, follow" />
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="language" content="English" />

                <meta name="title" content="Zipher Solutions" />
                <meta
                    name="description"
                    content="Zipher Solutions provides intelligent, modern, and effective solutions in the field of software, hardware, networking, cloud infrastructure, and IoT."
                />
                <meta
                    name="keywords"
                    content="information technology, it, services, solutions, iot, software, hardware, development, networking, cloud infrastructure, services, web3, technology"
                />
                <meta
                    itemprop="image"
                    content="https://ziphersolutions.com/images/logo_zipher.png"
                />

                {/* <!-- Open Graph / Facebook --> */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://ziphersolutions.com/" />
                <meta property="og:title" content="Zipher Solutions" />
                <meta
                    property="og:description"
                    content="Zipher Solutions provides intelligent, modern, and effective solutions in the field of software, hardware, networking, cloud infrastructure, and IoT."
                />
                <meta
                    property="og:image"
                    content="https://ziphersolutions.com/images/logo_zipher.png"
                />

                {/* <!-- Twitter --> */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://ziphersolutions.com/" />
                <meta property="twitter:title" content="Zipher Solutions" />
                <meta
                    property="twitter:description"
                    content="Zipher Solutions provides intelligent, modern, and effective solutions in the field of software, hardware, networking, cloud infrastructure, and IoT."
                />
                <meta
                    property="twitter:image"
                    content="https://ziphersolutions.com/images/logo_zipher.png"
                />
            </Helmet>
            <AppLayout>
                <AboutBanner />
                <AboutAbout />
                <AboutCoreTeam />
                <AboutProudFil />
                <AboutContact />
                <Footer />
            </AppLayout>
        </>
    );
};

export default AboutPage;
