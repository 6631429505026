import React, { useEffect } from 'react';
import hero_a from '../../../static/svg/about-hero-a.svg';
import hero_b from '../../../static/svg/about-hero-b.svg';
import hero_c from '../../../static/svg/about-hero-c.svg';

//gsap happens
import { gsap, Power3 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export const AboutAbout = () => {
    useEffect(() => {
        gsap.from('.gsap_about_about_text', {
            duration: 1,
            y: '10',
            opacity: 0,
            ease: Power3.easeInOut,
            scrollTrigger: {
                trigger: '#about_about',
                start: 'top 45%',
                end: 'bottom 1%',
                // markers: true,
                toggleActions: 'play none none reverse',
            },
            stagger: 0.2,
        });
    }, []);

    return (
        <>
            <section
                id="about_about"
                className="about d-flex-col align-items-center justify-content-center"
            >
                <div className="about_container d-flex-col align-items-center justify-content-center">
                    <div className="about_container_content d-flex-col align-items-center justify-content-center">
                        {/* HERO 1 */}
                        <div className="about_container_content_hero d-flex-row align-items-center justify-content-between gap-4">
                            <div className="myhero_img d-flex-col align-items-center justify-content-center">
                                <img src={hero_a} alt="About us Hero" />
                            </div>
                            <div className="gsap_about_about_text myhero_text d-flex-col gap-1">
                                <p>
                                    Zipher started as a <span> freelancing </span> job
                                </p>
                                <p>
                                    Zipher Solutions was founded in 2021. What started as a
                                    freelancing job later evolved into a company that offers IoT and
                                    Software Solutions to clients all over the world.
                                </p>
                                <p></p>
                            </div>
                        </div>
                        {/* HERO 2 */}
                        <div className="about_container_content_hero d-flex-row align-items-center justify-content-between gap-4">
                            <div className="gsap_about_about_text myhero_text d-flex-col gap-1">
                                <p>
                                    Transition to <span> Fourth Industrial Revolution </span>
                                </p>
                                <p>
                                    Our main goal is to provide IoT solutions to companies and
                                    clients that want to transition into Industry 4.0
                                </p>
                                <p></p>
                            </div>
                            <div className="myhero_img d-flex-col align-items-center justify-content-center">
                                <img src={hero_b} alt="About us Hero" />
                            </div>
                        </div>
                        {/* HERO 3 */}
                        <div className="about_container_content_hero d-flex-row align-items-center justify-content-between gap-4">
                            <div className="myhero_img d-flex-col align-items-center justify-content-center">
                                <img src={hero_c} alt="About us Hero" />
                            </div>
                            <div className="gsap_about_about_text myhero_text d-flex-col gap-1">
                                <p>
                                    Expert <span> Filipino </span> IoT Engineers
                                </p>
                                <p>
                                    Zipher also aims to showcase the talents of our team of
                                    professional Filipino IoT Engineers as one of the best in the
                                    world.
                                </p>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
